<i18n>
{
  "ru": {
    "guestChoice":"Выбор гостей",
    "guests": "Гости",
    "adult": "|{n} взрослый | {n} взрослых | {n} взрослых",
    "guest": "|{n} гость | {n} гостя | {n} гостей",
    "child": " без детей |{n} ребёнок | {n} ребёнка | {n} ребёнка",
    "children": "{n} детей",
    "ok": "Сохранить",
    "addChild":"Добавить ребёнка",
    "withPets": "питомец"
  },
  "en": {
    "guestChoice":"Guest Choice",
    "guests": "Guests",
    "adult": "|{n} adult | {n} adult | {n} adult",
    "guest": "|{n} guest | {n} guests | {n} guests",
    "child": " without children |{n} child | {n} child's | {n} child's",
    "children": "{n} children's",
    "ok": "Save",
    "addChild":"Add child",
    "withPets": "pet"
  }
}
</i18n>
<template>
  <div class="select-guests" :class="view">
    <button
      class="select-guests--btn"
      :class="{ merge: isMerge, active: isMerge && maxGuests > 1 }"
      @click="showModal"
      type="button"
    >
      <template v-if="isMerge">
        <div>{{ $tc("guest", maxGuests) }}</div>
      </template>

      <template v-else>
        <div v-if="isMain" class="title">
          {{ $t("guests") }}
        </div>
        <div class="body">
          {{ getGuestsText }}
          <span v-if="!this.guests.childrens.length && !this.guests.pets.value" class="empty">
            {{ $tc("child", 0) }}
          </span>
        </div>
        <span class="ico-arrow"></span>
      </template>
    </button>
    <Modal
      ref="modalRef"
      v-model="isGuestsModal"
      class="custom-modal"
      :title="$t('guestChoice')"
      @close="closeModal()"
    >
      <div class="select-guests__body" v-if="selectGuests">
        <GuestOptions
          :guests="selectGuests"
          :childrenWithoutAgeError="childrenWithoutAgeError"
          @changeChildren="changeChildren"
          @changeAdults="changeAdults"
          @changePetsValue="changePetsValue"
        />
      </div>
      <div slot="footer">
        <button class="button button_size_md button_blue button_w-100" @click="apply">
          {{ btnTitle ? btnTitle : $t("ok") }}
        </button>
      </div>
    </Modal>
  </div>
</template>

<script>
import GuestOptions from './GuestOptions';
import Modal from '@sutochno/modal'
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import eventsDispatcher from "./eventsDispatcher.js";
import VueCookies from "vue-cookies";
import getHost from './getHost.js';

export default {
  name: "SelectGuestsMobile",
  components: {
    Modal,
    GuestOptions
  },
  inject: ['isPlatform'],
  props: {
    view: {
      type: String,
    },
    maxGuestsObject: {
      type: Number,
    },
    localGuests: {
      type: Object,
    },
    isMerge: {
      type: Boolean,
      default: false,
    },
    btnTitle:{
      type: String,
      default: null,
    },
    isBlockingClick:{
      type: Boolean,
      default: false,
    },
    guestsInsert: {
      type: Number,
      default: 0
    },
    isMain: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    // ...mapState("search", ["guests"]),
    ...mapGetters("hotel", ["guestsNum"]),
    ...mapState("hotel", { guestsHotel: "guests" }),
    ...mapGetters("search", ["maxGuests"]),
    guestsAdult() {
      if (this.localGuests) {
        if (this.maxGuests > this.maxGuestsObject) {
          return 1;
        } else {
          if (this.view != "page-hotel") {
            return this.selectGuests.adults;
          } else {
            return this.guestsHotel.adults;
          }
        }
      } else {
        return this.guests.adults;
      }
    },
    guestsChildrens() {
      if (this.localGuests) {
        if (this.maxGuests > this.maxGuestsObject) {
          return 0;
        } else {
          if (this.view != "page-hotel") {
            return this.selectGuests.childrens.length;
          } else {
            return this.guestsHotel.childrens.length;
          }
        }
      } else {
        return this.guests.childrens.length;
      }
    },
    getGuestsText() {
      let result = this.$tc("adult", this.guests.adults);
      if (this.guests.childrens.length) {
        let children =
          this.guests.childrens.length >= 5
            ? this.$t("children", { n: this.guests.childrens.length })
            : this.$tc("child", this.guests.childrens.length);
        result += ", " + children;
      }
      
      if (this.guests.pets.value) {
        result += ", " + this.$t("withPets");
      }
      return result;
    },
  },
  watch: {
    isGuestsModal(val) {
      if (!this.isPlatform && this.view == "page-detail" || this.view == "page-hotel") {
        // хак для ios, модалка в модалке не корректно работают
        this.setModalsToggle({
          name: "guestsDetail",
          val: val,
        });
      }
    },
  },
  mounted() {
    this.$nextTick(async () => {

      this.selectGuests = JSON.parse(JSON.stringify(this.guests))

      const cookieGuests = VueCookies.get("select_guests")
        ? VueCookies.get("select_guests") : null

      if(this.guestsInsert) {
        this.selectGuests.adults = this.guestsInsert
      } else {
        if (cookieGuests) {
          this.selectGuests.adults = cookieGuests.guests.adults ? cookieGuests.guests.adults : 1
          this.selectGuests.childrens = cookieGuests.guests.childrens && cookieGuests.guests.childrens.length > 0 ? cookieGuests.guests.childrens : []
          this.selectGuests.pets = cookieGuests.guests.pets ? cookieGuests.guests.pets : {
            value: false,
            description: ""
          }
        }
      }

      if(this.selectGuests) {
        this.$emit('cntGuests', this.selectGuests.adults + this.selectGuests.childrens.length)
      }

      await this.$nextTick()
      this.apply()
    })
  },
  methods: {
    ...mapMutations("application", ["setModalsToggle"]),
    ...mapActions("search", ["updateAdults", "updateChildrens"]),
    closeModal() {
      eventsDispatcher('WIDGET_MODAL_CLOSE', document)
      this.$emit('guestsClose')
      this.selectGuests = JSON.parse(JSON.stringify(this.guests));
    },
    saveLocalStorage() {
      const EXPIRED = '10y'

      const PAYLOAD = {
        guests: this.guests,
      };

      VueCookies.set("select_guests", PAYLOAD, EXPIRED, null, getHost().domainDot);
    },
    showModal() {
      eventsDispatcher('WIDGET_MODAL_SHOW', document)
      this.$emit("clickGuests");
      if (!this.isBlockingClick) this.$refs.modalRef.show();
      this.$emit("click");
    },
    hideModal() {
      this.$refs.modalRef.close();
    },
    showError(){
      this.childrenWithoutAgeError = true;
    },
    apply(e) {
      if(this.selectGuests.childrens?.includes(null)){
        e.preventDefault()
        this.showError();
        return false;
      }
      if (this.selectGuests.adults != this.guests.adults) {
          // this.$store.commit("search/setAdults", this.selectGuests.adults);
          this.guests.adults = this.selectGuests.adults
          this.saveLocalStorage();
        }
        let childs = this.selectGuests.childrens.filter((x) => x !== null)
        if (childs.length != this.guests.childrens.length) {
          this.guests.childrens = childs
          this.saveLocalStorage();
        }
        if(this.selectGuests.pets && this.guests.pets && this.selectGuests.pets.value !== this.guests.pets.value){
          this.guests.pets = {
            value: this.selectGuests.pets.value,
            description: this.guests.pets.description
          }
        }
        // this.updateChildrens(childs);
        this.$emit('applyGuests', this.guests)
        this.$refs.modalRef.close();
    },
    changeAdults(val){
      this.selectGuests.adults = val;
    },
    changeChildren(val) {
      this.selectGuests.childrens = val
    },
    changePetsValue(val) {
      this.selectGuests.pets = {
        value: val,
        description: ""
      }
    },
  },
  data() {
    return {
      selectGuests: null,
      isGuestsModal: false,
      guests: {
        adults: 2,
        childrens: [],
        pets: {
          value: false,
          description: ""
        }
      },
      childrenWithoutAgeError: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.btn-ok {
  height: 42px;
}
.guests {
  &__item {
    margin-top: 15px;
  }
}
.select-guests {
  max-width: 100%;
  margin-bottom: 10px;

  &__body {
    padding: 20px;
    z-index: 1;
    position: relative;
    font-size: 14px;
  }

  &--fade-enter-active,
  &--fade-leave-active {
    opacity: 1;
    transition: opacity 0.15s ease;
  }
  &--fade-enter,
  &--fade-leave-to {
    opacity: 0;
  }
  &--btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    cursor: pointer;
    margin: 0;
    outline-style: none;
    background: #fff;
    line-height: 1;
    color: #000;
    border: none;
    height: 60px;
    width: 100%;
    border-radius: 3px;
    padding: 0 30px 0 10px;
    position: relative;
    box-shadow: inset 0 0 0 1px #fff;

    .title {
      font-size: 14px;
      opacity: 0.5;
      margin-bottom: 4px;
    }
    .body {
      font-family: Inter,sans-serif;
      font-size: 16px;
      span {
        &.empty {
          color: #717171;
        }
      }
    }
    .ico-arrow {
      background-repeat: no-repeat;
      background-size: contain;
      display: block;
      height: 14px;
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%) scaleY(1);
      width: 14px;
      background-image: url("./assets/arrow-main.svg");
      transition: transform .3s;
    }
  }
  &.page-search {
    .select-guests--btn {
      &.merge {
        height: 32px;
        @media screen and (max-width: 560px){
          height: 42px;
        }
        padding: 0 15px;
        color: #000;
        border: 1px solid #d1d1d1;
        &.active {
          border: 1px solid #000;
        }
      }
    }
  }
  &.page-search,
  &.page-hotel {
    max-width: max-content;
    margin-bottom: 0;
    .select-guests--btn {
      height: 36px;
      font-size: 14px;
      border: 1px solid #d1d1d1;
      border-radius: 3px;
      white-space: nowrap;
      .ico-arrow {
        right: 10px;
      }
    }
  }
  &.page-hotel {
    // margin-left: 2px;
    max-width: max-content;
    .select-guests--btn {
      height: 36px;
      padding: 0 26px 0 12px;
      font-size: 14px;
      min-width: 80px;
      .ico-arrow {
        right: 8px;
      }
    }
  }
  &.page-detail {
    .select-guests--btn {
      height: 36px;
      border: 1px solid #d1d1d1;
      border-radius: 3px;
      font-size: 14px;
      padding: 0 24px 0 14px;
      .ico-arrow {
        right: 12px;
      }
    }
  }
}
</style>
