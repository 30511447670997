<i18n>
{
  "ru": {
    "guests": "Гости",
    "adult": "|{n} взрослый | {n} взрослых | {n} взрослых",
    "child": " без детей |{n} ребёнок | {n} ребёнка | {n} ребёнка",
    "children": "{n} детей",
    "addChild":"Добавить ребёнка",
    "done":"Готово",
    "withPets": "питомец"
  },
  "en": {
    "guests": "Guests",
    "adult": "|{n} adult | {n} adult | {n} adult",
    "child": " without children |{n} child | {n} child's | {n} child's",
    "children": "{n} children's",
    "addChild":"Add child",
    "done":"Done",
    "withPets": "pet"
  }
}
</i18n>
<template>
  <div v-click-outside="close" class="select-guests" :class="view">
    <button
      class="select-guests--btn"
      :class="{
        active: isSelect && isMain,
        'active-simple': isSelect,
        'select-guests_current': isSearch,
        'page-inner': !isMain,
      }"
      @click="showSelect()"
      type="button"
    >
      <div class="title" v-if="isMain">{{ $t("guests") }}</div>
      <div class="body">
        {{ getGuestsText }}
        <span v-if="!this.guests.childrens.length && !this.guests.pets.value" class="empty">
          {{ $tc("child", 0) }}
        </span>
      </div>
      <span class="ico-arrow" v-if="!isSearch"></span>
    </button>
    <transition name="select-guests--fade">
      <div v-show="isSelect" class="select-guests--main tmp-font--medium" id="sel" ref="selectGuests">
        <GuestOptions
          v-if="selectGuests"
          :guests="selectGuests"
          :childrenWithoutAgeError="childrenWithoutAgeError"
          @changeChildren="changeChildren"
          @changeAdults="changeAdults"
          @changePetsValue="changePetsValue"
        />
        <div class="select-guests__buttons">
          <UIButton @click="close('redirect')" buttonTheme="dark">
            {{ $t("done") }}
          </UIButton>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import GuestOptions from './GuestOptions'
import { mapState, mapGetters, mapActions } from "vuex";
import vClickOutside from '@sutochno/v-click-outside';
import VueCookies from "vue-cookies";
import getHost from './getHost.js';
import UIButton from "./UIKit/UIButton"

export default {
  name: "SelectGuestsDesktop",
  components: {
    GuestOptions,
    UIButton
  },
  directives: {
    "click-outside": vClickOutside,
  },
  inject: ['isPlatform'],
  props: {
    view: {
      type: String,
    },
    maxGuestsObject: {
      type: Number,
    },
    isBlockingClick:{
      type: Boolean,
      default: false,
    },
    isChangeByClose: {
      type: Boolean,
      default: false
    },
    isSearch: {
      type: Boolean,
      default: false
    },
    isMain: {
      type: Boolean,
      default: false
    },
    guestsInsert: {
      type: Number,
      default: 0
    },
  },
  computed: {
    getGuestsText() {
      let result = this.$tc("adult", this.guests.adults);
      if (this.guests.childrens.length) {
        let children =
          this.guests.childrens.length >= 5
            ? this.$t("children", { n: this.guests.childrens.length })
            : this.$tc("child", this.guests.childrens.length);
        result += ", " + children;

        if (this.guests.pets.value) {
          result += ", ..."
        }
      }

      if (!this.guests.childrens.length && this.guests.pets.value) {
        result += ", " + this.$t("withPets");
      }
      return result;
    },
  },
  watch: {
    selectGuests: {
      deep: true,
      handler(val) {
          this.sendToStore(val)
      }
    },
    guests: {
      deep: true,
      handler(val) {
        this.$emit('applyGuests', val)
      }
    },
    isSelect(val) {
      this.$emit(val ? 'show' : 'close')
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.selectGuests = JSON.parse(JSON.stringify(this.guests))

      const cookieGuests = VueCookies.get("select_guests")
        ? VueCookies.get("select_guests") : null

      if(this.guestsInsert) {
        this.selectGuests.adults = this.guestsInsert
      } else {
        if (cookieGuests) {
          this.selectGuests.adults = cookieGuests.guests.adults ? cookieGuests.guests.adults : 1
          this.selectGuests.childrens = cookieGuests.guests.childrens && cookieGuests.guests.childrens.length > 0 ? cookieGuests.guests.childrens : []
          this.selectGuests.pets = cookieGuests.guests.pets ? cookieGuests.guests.pets : {
            value: false,
            description: ""
          }
        }
      }

      if(this.selectGuests) {
        this.$emit('cntGuests', this.selectGuests.adults + this.selectGuests.childrens.length)
      }
    })
  },
  methods: {
    ...mapActions("search", ["updateAdults", "updateChildrens"]),
    sendToStore(val) {
      this.guests.adults = val.adults
      this.guests.childrens = val.childrens
      this.guests.pets = {
        value: val.pets.value,
        description: this.guests.pets.description
      }
      this.saveLocalStorage();
      
      // this.updateChildrens(childs);
    },
    showSelect() {
      if (this.isBlockingClick){
        this.$emit("click");
      } else {
        if (!this.isSelect) {
          this.$emit("click");
        }
        this.isSelect = !this.isSelect;
      }
      this.$emit("clickGuests");
    },
    saveLocalStorage() {
      const EXPIRED = '10y'

      const PAYLOAD = {
        guests: this.guests,
      };

      VueCookies.set("select_guests", PAYLOAD, EXPIRED, null, getHost().domainDot);
    },
    showError(){
      this.childrenWithoutAgeError = true;
      this.isSelect = true;
    },
    close(event) {
      if(event === 'redirect' && this.selectGuests.childrens?.includes(null)){
        this.showError()
        return false;
      }
      this.isSelect = false;
      const local = JSON.parse(JSON.stringify(this.selectGuests))
      local.childrens.splice(local.childrens.length - 1, 1)

      if(this.isChangeByClose && (JSON.stringify(local) !== JSON.stringify(this.guests))) {
        this.sendToStore(this.selectGuests)
      }

      if(event === 'redirect'){
        this.$emit('clickDone')
      }
    },
    childrensOpen(val, i) {
      this.isSelectOpen = val
      if(val) {
        this.$nextTick(() => {
          setTimeout(() => {
            this.$refs.selectGuests.scrollTo({
              top: this.$refs[`guest_${i}`][0].$el.offsetTop - 15,
              behavior: "smooth",
            });
          }, 150);
        })
      }
    },
    changeAdults(val){
      this.selectGuests.adults = val;
    },
    changeChildren(val) {
      this.selectGuests.childrens = val
    },
    changePetsValue(val) {
      this.selectGuests.pets = {
        value: val,
        description: "",
      }
    },
  },
  data() {
    return {
      isSelect: false,
      selectGuests: null,
      isSelectOpen: false,
      guests: {
        adults: 2,
        childrens: [],
        pets: {
          value: false,
          description: ""
        }
      },
      childrenWithoutAgeError: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.guests {
  &__item {
    margin-top: 15px;
  }
}
.select-guests {
  position: relative;
  &--fade-enter-active,
  &--fade-leave-active {
    opacity: 1;
    transition: opacity 0.15s ease;
  }
  &--fade-enter,
  &--fade-leave-to {
    opacity: 0;
  }

  &__child {
    padding-left: 5px;
  }
  &__buttons {
    background: #fff;
    padding: 20px 0;
    position: sticky;
    bottom: 0;
    z-index: 20;
  }
  &--btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    cursor: pointer;
    padding: 12px 46px 12px 20px;
    margin: 0;
    outline-style: none;
    background: #fff;
    line-height: 1;
    color: #000;
    height: 60px;
    border: none;
    width: 100%;
    min-width: 248px;
    border-radius: 0px 3px 3px 0px;
    position: relative;
    white-space: nowrap;
    box-shadow: inset 0 0 0 1px #fff;
    transition: box-shadow .3s;

    .title {
      font-size: 14px;
      margin-bottom: 7px;
      color: #717171;
    }
    .body {
      margin-top: -2px;
      span {
        &.empty {
          color: #717171;
        }
      }
      font-family: Inter,sans-serif;
      font-size: 16px;
    }
    &.active-simple {
      .ico-arrow {
        transform: translateY(-50%) scaleY(-1);
      }
    }
    .ico-arrow {
      background-repeat: no-repeat;
      background-size: contain;
      display: block;
      height: 14px;
      // margin-top: -6px;
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%) scaleY(1);
      width: 14px;
      background-image: url("./assets/arrow-main.svg");
      transition: transform .3s;
    }
    &.active {
      box-shadow: inset 0 0 0 1px #f51449;
      .ico-arrow {
        //transform: rotate(180deg);
      }
    }
    &.page-inner {
      flex-direction: row;
      justify-content: flex-start;
    }
  }
  &--main {
    position: absolute;
    top: 100%;
    z-index: 4;
    width: 100%;
    margin-top: 4px;
    background: #fff;
    padding: 15px 20px 0 20px;
    font-size: 14px;
    border-radius: 20px;
    box-shadow: 0 1px 5px rgba(20,45,136,.08), 0 4px 15px rgba(20,45,136,.06);
  }
  &.page-search {
    .select-guests--btn {
      height: 60px;
      flex-direction: row;
      justify-content: flex-start;
      min-width: 100%;
      transition: border .3s;
      &.select-guests_current {
        color: #000;
        border: 1px solid #000;
      }
      &:hover {
        border: 1px solid #a6a6a6;
      }
    }
  }
  &.page-search,
  &.page-hotel {
    .select-guests--btn {
      height: 42px;
      border: 1px solid #d8d8d8;
      border-radius: 3px;
    }
    .select-guests--main {
      outline: 1px solid #d1d1d1;
      margin-left: 0;
      margin-top: 4px;
    }
  }
  &.page-hotel {
    margin-left: 20px;
    .select-guests--btn {
      height: 42px;
    }
  }
  &.page-detail {
    .select-guests--btn {
      height: 42px;
      border: 1px solid #d1d1d1;
      border-radius: 3px;
      font-size: 14px;
      padding: 12px 46px 12px 15px;
    }
    .select-guests--main {
      outline: 1px solid #d1d1d1;
      margin-left: 0;
      margin-top: 3px;
      left: auto;
      right: 0;
      width: 100%;
    }
  }
}
::-webkit-scrollbar {
  width: 10px;
  background: transparent;
}
::-webkit-scrollbar-thumb {
  width: 4px;
  background: transparent;

  border-radius: 3px;
  box-shadow: inset 0 0 0 2px grey;
  border: 3px solid transparent;
}
</style>
