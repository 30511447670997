
<template>
  <div class="sc-selectguests">
    <SelectGuestsMobile
      v-if="isMobile"
      :guestsInsert="guestsInsert"
      :isMain="isMain"
      ref="mobile"
      @applyGuests="$emit('applyGuests', $event)"
      @cntGuests="$emit('cntGuests', $event)"
    />
    <SelectGuestsDesktop 
      v-else
      :guestsInsert="guestsInsert"
      :isMain="isMain"
      ref="desctop"
      @applyGuests="$emit('applyGuests', $event)"
      @cntGuests="$emit('cntGuests', $event)"
      @clickDone="clickDone"
    />
  </div>
</template>

<script>
import SelectGuestsDesktop from './Desktop.vue'
import SelectGuestsMobile from './Mobile.vue'

export default {
  name: "SelectGuests",
  components: {
    SelectGuestsDesktop,
    SelectGuestsMobile
  },
  props: {
    guestsInsert: {
      type: Number,
      default: 0
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    isMain: {
      type: Boolean,
      default: false
    },
    isPlatform: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    clickDone(){
      if(this.isPlatform){
        this.$emit('clickDone')
      }
    },
    guestError(){
      if(this.isMobile){
        this.$refs.mobile.showError()
      } else {
        this.$refs.desctop.showError()
      }
    }
  }
}
</script>

<style lang="scss">
.sc-selectguests {
  
}
</style>
