

<template>
  <button type="button" class="ui-button" @click="$emit('click')" :class="theme">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "UIButton",
  data() {
    return {
      buttonThemeList: ["default", "red", "dark", "pale-blue", "light"],
    };
  },
  props: {
    buttonTheme: String,
    default: "default",
  },
  computed: {
    theme() {
      if (this.buttonThemeList.includes(this.buttonTheme)) {
        return this.buttonTheme;
      }
      return "default";
    },
  },
};
</script>

<style lang="scss" scoped>
.ui-button {
  padding: 14px 24px;
  width: 100%;
  height: auto;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 14px;
  border: none;
  outline: none;

  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #1c1c1c;

  transition: all 0.2s;
  cursor: pointer;
}

.default {
  background: #ffffff;
  border: 1px solid #e7e9f3;

  &:hover {
    border: 1px solid #c0c3d3;
  }
  &:active {
    background: #f8faff;
    border: 1px solid #c0c3d3;
  }
  &:disabled {
    border: 1px solid #e7e9f3;
    color: rgba(28, 28, 28, 0.2);
  }
}

.red {
  background: #f51449;
  color: #ffffff;

  &:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)),
      #f51449;
  }
  &:active {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
      #f51449;
  }
  &:disabled {
    background: rgba(245, 20, 73, 0.2);
  }
}

.dark {
  background: #1c1c1c;
  color: #ffffff;

  &:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      #1c1c1c;
  }
  &:active {
    background: #000000;
  }
  &:disabled {
    background: rgba(28, 28, 28, 0.2);
  }
}

.pale-blue {
  background: #f1f3fb;

  &:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)),
      #f4f5f9;
  }
  &:active {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.06)),
      #f4f5f9;
  }
  &:disabled {
    background: #f1f3fb;
    color: rgba(28, 28, 28, 0.2);
  }
}

.light {
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(20, 45, 136, 0.1),
    0px 6px 10px rgba(20, 45, 136, 0.02);

  &:hover {
    box-shadow: 0px 3px 5px rgba(20, 45, 136, 0.1),
      0px 6px 10px rgba(20, 45, 136, 0.02);
  }
  &:active {
    box-shadow: 0px 1px 3px rgba(20, 45, 136, 0.1),
      0px 6px 9px rgba(20, 45, 136, 0.02);
  }
  &:disabled {
    box-shadow: 0px 1px 5px rgba(20, 45, 136, 0.1),
      0px 6px 10px rgba(20, 45, 136, 0.02);
    color: rgba(28, 28, 28, 0.2);
  }
}
</style>
