import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import i18n from "./i18n";

import VueLocalStorage from 'vue-localstorage'
Vue.use(VueLocalStorage)

Vue.config.productionTip = false;
Vue.config.ignoredElements = ['header-tag']

const ID = "#search-widget-vuejs";
const elemID = document.getElementById("search-widget-vuejs");
const location = [
  'data-location-id',
  'data-location-type',
  'data-location-url',
  'data-location-title'
].reduce((acc, key) => {
  const value = elemID.getAttribute(key);
  if(value && !acc) acc = {}
  if(value) acc[key.replace("data-location-", "")] = value;
  return acc;
}, null)

let datesInsert = null;
const pathnameArray = window.location.pathname.split('/');
if (elemID?.getAttribute('data-occupied')) {
  datesInsert = elemID.getAttribute('data-occupied');
} else if (pathnameArray[pathnameArray.length - 1] === 'newyear') {
  datesInsert = '2023-12-31;2024-01-07';
}
let guestsInsert = elemID?.getAttribute('data-guests') || null;

new Vue({
  store,
  i18n,
  data: {
    datesInsert,
    guestsInsert,
    location
  },
  provide: function () {
    return {
      datesInsert: this.datesInsert,
      guestsInsert: this.guestsInsert,
      location: this.location
    }
  },
  render: h => h(App)
}).$mount(ID);
