<i18n>
{
	"ru": {
    "in": "Заезд",
		"btn": "Сохранить",
		"out": "Отъезд",
		"outLower": "отъезд",
		"reset": "Сбросить даты",
    "pressDate": "Введите даты поездки чтобы увидеть цены",
    "titleCalendar": "Выбор дат",
    "titleGuests": "Выбор гостей",
    "titleBooking": "Бронирование",
    "choiceOut": "Выберите дату отъезда",
    "nights": "менее суток | за сутки | за {n} суток | за {n} суток",
    "nightsMin": "менее суток | {n} сутки | {n} суток | {n} суток"
	},
	"en": {
	}
}
</i18n>
<template>
  <div class="booking-datepicker">
    <div class="loading" v-if="!viewCalendar" />
    <template v-else>
      <div class="booking-datepicker__header">
        <p class="booking-datepicker__dates">
          <span v-if="dateViews.in && dateViews.out">
            <template>{{ `${format(date.in)} — ${format(date.out)}` }}</template>
            <template v-if="nextYear">{{ `, ${nextYear}` }}</template>
          </span>
          <span v-else-if="dateViews.in">{{
            `${format(dateViews.in)} — ${$t("outLower")}`
          }}</span>
          <span v-else>{{ $t("in") }}</span>
        </p>
        <p class="booking-datepicker__header-text">
          <span v-if="dateViews.in && dateViews.out">{{
            $tc("nightsMin", daysCount)
          }}</span>
          <span v-else-if="dateViews.in">{{ $t("choiceOut") }}</span>
          <span v-else>{{ $t("pressDate") }}</span>
        </p>
      </div>
      <div class="main-datepicker" v-if="viewCalendar">
        <div class="main-datepicker-calendar" id="calendar">
          <Calendar
            ref="CalendarRef"
            v-model="date"
            changeRange="in"
            :begin-date="new Date()"
            :start-disable="new Date()"
            :count-month="13"
            :employment="employment"
            :dateViews.sync="dateViews"
            :daysCount.sync="daysCount"
            :cntMin="cntMin"
            :fill-mode="fillMode"
            orientation="vertical"
            lang="ru-RU"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { Calendar } from "@sutochno/datepickerext";
import { mapState } from "vuex";
import _ from "lodash";

export default {
  name: "CalendarMobile",
  components: {
    Calendar
  },
  props: {
    bookingView: {
      type: Boolean,
      default: false,
    },
    cntMin: {
      type: Number,
      default: null
    },
    employment: {
      type: Array,
      default: () => []
    },
    datesInsert: {
      type: Object
    },
    fillMode: {
      type: String,
      default: 'in'
    }
  },
  data() {
    return {
      date: {},
      dateViews: {
        in: null,
        out: null,
      },
      daysCount: 0,
      viewCalendar: false
    };
  },
  mounted() {
    this.$nextTick(async () => {
      await this.$nextTick()
      if(this.datesInsert && this.datesInsert.in && this.datesInsert.out)
        this.date = {
          in: new Date(this.datesInsert.in),
          out: new Date(this.datesInsert.out)
        }
      setTimeout(() => {
        this.$set(this, 'viewCalendar', true)
      }, 350);
    })
  },
  watch: {
    isSelectedDate(value) {
      this.$emit("choice", value);
    },
    watchDateIn(val, oVal) {
      if (val !== oVal) {
        this.$emit('send')
      }
    },
    daysCount(val) {
      this.$emit('update:daysCount', val)
    },
    dateViews: {
      deep: true,
      handler(val, oVal) {
        this.$emit('update:dateViews', val)
        this.$emit('disabled', 
          this.date && this.date.in && this.date.out
            ? false : true
        )

        if(val &&
          (val.in && val.out) ||
          val &&
          (val.in && val.error && val.error.getTime() > val.in.getTime())
        ) {
          this.setOccupied(val.in, val.out || val.error)
        }
        const send = () => {
          setTimeout(() => {
            this.$emit('cntCheck')
            this.$emit('send')
          }, 0);
        };
        if (val.in !== oVal.in) {
          send();
        } else {
          setTimeout(() => {
            if (this.daysCount >= this.cntMin) {
              send();
            }
          }, 0);
        }
      },
    },
  },
  computed: {
    ...mapState("search", ["checkIn", "checkOut"]),
    watchDateIn() {
      // watch-ер не может просматривать old value у свойства объекта
      // а мне нужно обязательно проверить что старое значение не совпадает с новым
      // по этому я возвращаю в виде одной даты и уже этот computed смотрю в watch
      return this.dateViews && this.dateViews.in;
    },
    watchDateOut() {
      // watch-ер не может просматривать old value у свойства объекта
      // а мне нужно обязательно проверить что старое значение не совпадает с новым
      // по этому я возвращаю в виде одной даты и уже этот computed смотрю в watch
      return this.dateViews && this.dateViews.out;
    },
    nextYear() {
      if (this.date && this.date.in && this.date.out) {
        const nextY = new Date(this.date.out).getFullYear();
        const nowY = new Date().getFullYear();
        return nextY > nowY ? nextY : null;
      } else {
        return null;
      }
    },
    isSelectedDate() {
      return this.dateViews.in || this.dateViews.out;
    },
    dateCheck() {
      if (this.getObjectData.properties.check_in_out_rules) {
        return this.getObjectData.properties.check_in_out_rules.properties.reduce(
          (acc, v) => {
            if (v.name == "check_in") {
              acc = {
                ...acc,
                in: v.value,
              };
            }
            if (v.name == "check_out") {
              acc = {
                ...acc,
                out: v.value,
              };
            }
            return acc;
          },
          {}
        );
      } else {
        return this.getObjectData.properties.additional_payment.properties.reduce(
          (acc, v) => {
            if (v.name == "check_in") {
              acc = {
                ...acc,
                in: v.value,
              };
            }
            if (v.name == "check_out") {
              acc = {
                ...acc,
                out: v.value,
              };
            }
            return acc;
          },
          {}
        );
      }
    },
  },
  methods: {
    format(date) {
      return date
        .toLocaleString("ru-RU", {
          day: "numeric",
          month: "short",
        })
        .replace(".", "");
    },
    reset() {
      this.$set(this.date, "in", null);
      this.$set(this.date, "out", null);
      this.$refs.CalendarRef.reset();
      this.setOccupied(this.date.in, this.date.out);
      this.$emit("reset")
      this.$emit("input", this.date);
    },
    setOccupied(dateIn, dateOut) {
      this.$store.commit("search/setCheckIn", dateIn);
      this.$store.commit("search/setCheckOut", dateOut);
    },
  },
};
</script>

<style lang="scss" scoped>
.booking {
  &-datepicker {
    &__dates {
      text-align: left;
      font-size: 22px;
      line-height: normal;
      font-weight: bold;
      color: #000;
    }
    &__reset {
      position: absolute;
      top: 0px;
      right: 10px;
      z-index: 201;
      font-size: 14px;
    }
    &__header {
      padding: 20px;
      background: #fff;

      &-text {
        text-align: left;
        margin-top: 10px;
        font-size: 14px;
        line-height: normal;
        font-weight: normal;
        color: #7f7f7f;
      }
    }
  }
}
.main-datepicker {
  &-calendar {
    ::v-deep .sc-datepickerext-calendar {
      &.sc-datepickerext-vertical {
        // height: calc(var(--vh, 1vh) * 100 - 226px);
        height: 100%;
        overflow: hidden;
      }
      .sc-datepickerext-calendar_body {
        height: calc(var(--vh, 1vh) * 100 - 271px);
        overflow: auto;
      }
    }
  }
}
</style>
