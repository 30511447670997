export default () => {
    const { host } = window.location
    let newHost = host;
    newHost = newHost.split('.')
    if(newHost.length >= 3) {
      newHost = newHost.slice(1)
    }

    newHost = newHost.join(".");
    let domain = `.${newHost}`

    if(newHost.indexOf('localhost') >= 0) {
      newHost = 'localhost'
      domain = 'localhost'
    }

    return {
        domain: newHost,
        domainDot: domain
    }
}