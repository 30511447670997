<template>
  <SearchWidget
    id="search-widget-vuejs"
    :datesInsert="datesInsertTransform"
    :guestsInsert="guestsInsert ? Number(guestsInsert) : null"
    :locationInsert="location ? location : null"
    :isPlatform="true"
    :isFixedPanel="true"
  />
</template>
<script>
import SearchWidget from './searchwidget/SearchWidget';


export default {
  name: "App",
  inject: ['datesInsert', 'guestsInsert', 'location'],
  components: {
    SearchWidget
  },
  computed: {
    datesInsertTransform() {
      // const dates = this.datesInsert ? this.datesInsert.split(';') : '2021-08-09;2021-08-26'.split(';')
      // const dates = this.datesInsert ? this.datesInsert.split(';') : null
      let dates = null

      const IsJSON = (str) => {
        try { JSON.parse(str) }
        catch (e) { return false }
        return true;
      }

      if(this.datesInsert) {

        if(JSON.parse(IsJSON(this.datesInsert))) {

          dates = JSON.parse(this.datesInsert)

          if(dates && dates.length === 2 && this.isDates(dates)) {
            return {
              in: new Date(dates.date_begin),
              out: new Date(dates.date_end)
            }
          }

        } else {

          dates = this.datesInsert.split(';')
          if(dates && dates.length === 2 && (!isNaN(Date.parse(dates[0])) && !isNaN(Date.parse(dates[1])))) {
            return {
              in: new Date(dates[0]),
              out: new Date(dates[1])
            }
          }

        }
      }

      return {
        in: null,
        out: null
      }
    }
  },
  methods: {
    isDates (dates) {
      return (dates.date_begin instanceof Date && !isNaN(dates.date_begin))
          || (dates.date_end instanceof Date && !isNaN(dates.date_end))
    },
  },
};
</script>
<style lang="scss">
body {
  margin: 0;
}
// #header-vuejs
header-tag {
  font-family: "PT Sans", sans-serif;
  // font-size: 15px;

  * {
    box-sizing: border-box;
  }

}
</style>
