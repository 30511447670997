var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
    callback: (isVisible, entry) => _vm.visibilityChanged(isVisible, entry),
    throttle: 1,
    intersection: {
      threshold: 0.001,
    },
  }),expression:"{\n    callback: (isVisible, entry) => visibilityChanged(isVisible, entry),\n    throttle: 1,\n    intersection: {\n      threshold: 0.001,\n    },\n  }"}],staticClass:"sc-searchwidget",class:[{}, `sc-searchwidget_${_vm.viewPort}`]},[(_vm.viewPort === 'desktop')?[_c('Suggestions',{ref:"baseSuggestions",staticClass:"sc-searchwidget__suggestions",attrs:{"locationInsert":_vm.locationPreselect ? _vm.locationPreselect : _vm.locationInsert},on:{"applyLocation":_vm.handlerLocation,"listSuggestions":function($event){_vm.listSuggestions = $event}}})]:[_c('Modal',{ref:"baseModalRefCity",attrs:{"title":_vm.$t('cityTitle')},on:{"close":function($event){return _vm.closeSuggestions()}}},[_c('SuggestionsModal',{ref:"baseSuggestions",staticClass:"sc-searchwidget__suggestions",attrs:{"locationInsert":_vm.locationInsert,"locationData":_vm.locationData},on:{"applyLocation":_vm.handlerLocation,"listSuggestions":function($event){_vm.listSuggestions = $event},"choice":function($event){return _vm.closeSuggestions()}}})],1),_c('div',{staticClass:"sc-searchwidget__suggestions-modal",class:{
        'sc-searchwidget__suggestions-modal__fill':
          _vm.locationData && _vm.locationData.title,
      },on:{"click":function($event){return _vm.showSuggestions()}}},[_vm._v(" "+_vm._s(_vm.locationData && _vm.locationData.title ? _vm.locationData.title : _vm.$t("label"))+" ")])],(_vm.viewPort === 'mobile')?_c('DatePickerModal',{ref:"calendar",attrs:{"fill-mode":_vm.fillMode},on:{"reset":_vm.handleReset,"apply":function($event){return _vm.doSearch(null, 'calendar')}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}):_vm._e(),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.clickOutside),expression:"clickOutside"}],staticClass:"sc-searchwidget__occupied",class:{
      'sc-searchwidget__occupied-mobile': _vm.viewPort === 'mobile',
    },style:({ zIndex: 1 })},[_c('div',{staticClass:"sc-searchwidget__occupied_in",class:{
        'sc-searchwidget__occupied_in-fill': _vm.dateViews.in,
        'sc-searchwidget__occupied_in-active': _vm.activeIn || _vm.activeFieldIn,
        'sc-searchwidget__occupied_in-active-choice':
          (_vm.activeIn || _vm.activeFieldIn) && _vm.dateViews.in,
      },attrs:{"data-cy":"checkIn"},on:{"click":function($event){return _vm.checkInOpen()}}},[_c('div',{staticClass:"title",class:{ active: _vm.dateViews.in }},[_vm._v(" "+_vm._s(_vm.$t("occupiedIn"))+" ")]),_c('div',{staticClass:"date"},[_vm._v(" "+_vm._s(_vm.dateViews.in ? _vm.format(_vm.dateViews.in) : "")+" ")])]),_c('div',{staticClass:"sc-searchwidget__occupied_out",class:{
        'sc-searchwidget__occupied_out-fill': _vm.dateViews.out,
        'sc-searchwidget__occupied_out-active': _vm.activeOut || _vm.activeFieldOut,
        'sc-searchwidget__occupied_in-active-choice':
          (_vm.activeOut || _vm.activeFieldOut) && _vm.dateViews.out,
      },attrs:{"data-cy":"checkOut"},on:{"click":function($event){return _vm.checkOutOpen()}}},[_c('div',{staticClass:"title",class:{ active: _vm.dateViews.out }},[_vm._v(" "+_vm._s(_vm.$t("occupiedOut"))+" ")]),_c('div',{staticClass:"date"},[_vm._v(" "+_vm._s(_vm.dateViews.out ? _vm.format(_vm.dateViews.out) : "")+" ")])]),(_vm.viewPort === 'desktop')?_c('div',{staticClass:"calendar-in"},[_c('DatePicker',{ref:"calendar",attrs:{"fill-mode":_vm.fillMode,"date-views":_vm.dateViews,"show-header":!_vm.dateViews.in || !_vm.dateViews.out},on:{"update:dateViews":function($event){_vm.dateViews=$event},"update:date-views":function($event){_vm.dateViews=$event},"visibleCalendar":function($event){_vm.visibleCalendar = $event},"reset":_vm.handleReset,"dateType":_vm.dateType},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1):_vm._e()]),_c('SelectGuests',{directives:[{name:"show",rawName:"v-show",value:(_vm.viewGuests || _vm.viewPort === 'desktop'),expression:"viewGuests || viewPort === 'desktop'"}],ref:"guests",staticClass:"sc-searchwidget__guest",attrs:{"guestsInsert":_vm.guestsInsert,"isMobile":_vm.viewPort === 'mobile' ? true : false,"isMain":true,"isPlatform":_vm.isPlatform},on:{"clickGuests":_vm.clickGuests,"applyGuests":_vm.handlerGuests,"cntGuests":_vm.showGuests,"clickDone":_vm.doSearch}}),_c('button',{staticClass:"sc-searchwidget__btn",class:{
      loading: _vm.isBtnDisabled || _vm.listSuggestions.isLoading,
      'sc-searchwidget__btn-no-guests': !_vm.viewGuests && _vm.viewPort === 'mobile'
    },attrs:{"type":"submit","disabled":_vm.isBtnDisabled || _vm.listSuggestions.isLoading},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.doSearch(null, 'search')}}},[_vm._v(" "+_vm._s(_vm.$t("startSearch"))+" ")]),(_vm.viewPort === 'desktop')?_c('div',{staticClass:"sc-searchwidget__select-location"},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("forExample")))]),_vm._l((_vm.preselected_cities),function(city,index){return [_c('span',{key:index,staticClass:"val",on:{"click":function($event){return _vm.preselectCity(city)}}},[_vm._v(" "+_vm._s(city.title)+" ")])]})],2):_vm._e(),_c('transition',{attrs:{"name":"slide"}},[(_vm.isFixedPanel && _vm.isFixed && _vm.viewPortFixed)?_c('div',{staticClass:"sc-searchwidget-fixed"},[_c('div',{staticClass:"sc-searchwidget-fixed__wrap"},[_c('button',{staticClass:"sc-searchwidget-fixed__button",class:{
            'sc-searchwidget-fixed__button_fill': _vm.date && _vm.date.in && _vm.date.out,
          },on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.doSearch('calendar')}}},[(_vm.date && _vm.date.in && _vm.date.out)?_c('span',[_vm._v(_vm._s(_vm.format(_vm.date.in, true))+" — "+_vm._s(_vm.format(_vm.date.out, true)))]):_c('span',[_vm._v(_vm._s(_vm.$t("occupiedIn"))+" — "+_vm._s(_vm.$t("occupiedOut")))])]),_c('button',{staticClass:"sc-searchwidget-fixed__button",class:{
            'sc-searchwidget-fixed__button_fill': _vm.guestsCnt > 1,
          },on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.doSearch('guests')}}},[_vm._v(" "+_vm._s(_vm.$tc("guest", _vm.guestsCnt))+" ")]),_c('button',{staticClass:"sc-searchwidget-fixed__button",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.doSearch('filter')}}},[_vm._v(" "+_vm._s(_vm.$t("filters"))+" ")])])]):_vm._e()]),(_vm.isPlatform)?_c('transition',{attrs:{"name":"map"}},[(_vm.isFixedPanel && _vm.isFixed && _vm.viewPortFixed)?_c('div',{staticClass:"to-map"},[_c('button',{staticClass:"to-map__btn",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.clickToMap.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("map"))+" ")])]):_vm._e()]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }