<i18n>
{
  "ru": {
    "adults": "Взрослые",
    "age": "от 18 лет"
  },
  "en": {
    "adults": "Adults",
    "age": "over 18"
  }
}
</i18n>

<template>
  <div class="guests-counter">
    <div class="guests-counter__text" @click="onClickCountGuests">
      <strong>{{ $t("adults") }}</strong>
      <span>{{ $t("age") }}</span>
    </div>
    <div class="guests-counter__editing">
      <UIButton
        class="guests-counter__button"
        buttonTheme="pale-blue"
        :disabled="modelValue === min"
        type="button"
        @click="decrement"
      >
        <span class="icon-app-minus" ></span>
      </UIButton>
      <span class="guests-counter__value">{{ modelValue }}</span>
      <UIButton
        class="guests-counter__button"
        buttonTheme="pale-blue"
        :disabled="modelValue === max"
        @click="increment"
        type="button"
      >
        <span class="icon-app-plus"></span>
      </UIButton>
    </div>
  </div>
</template>

<script>
import UIButton from "../UIKit/UIButton"

export default {
  name: "GuestsCounter",
  components: {
    UIButton
  },
  props: {
    modelValue: {
      type: Number,
      default: 0,
    },
    text: {
      type: String,
      default: "",
    },
    min: {
      type: Number,
      default: 1,
    },
    max: {
      type: Number,
      default: 100,
    },
    isAlert: {
      type: Boolean,
      default: false,
    },
    alertText: {
      type: String,
      default: "",
    },
  },
  methods: {
    increment() {
      if (this.modelValue < this.max) {
        let val = JSON.parse(JSON.stringify(this.modelValue));
        this.$emit("update:modelValue", val + 1);
      }
      if(this.$ga) this.$ga.event('draft_sa_m', 'click', 'btn_guests_plus');
    },
    decrement() {
      if (this.modelValue > this.min) {
        let val = JSON.parse(JSON.stringify(this.modelValue));
        this.$emit("update:modelValue", val - 1);
      }
      if(this.$ga) this.$ga.event('draft_sa_m', 'click', 'btn_guests_minus');
    },
    onClickCountGuests() {
      if(this.$ga) this.$ga.event('draft_sa_m', 'click', 'btn_guests_text');
    },
  },
};
</script>
<style lang="scss" scoped>
$border: solid 1px #d8d8d8;
$signsSize: 12px;

.guests-counter {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--default-fonts-color);

  &__text{
    display: flex;
    flex-direction: column;
    strong{
      font-weight: 500;
      line-height: 20px;
    }
    span{
      font-size: 12px;
      line-height: 16px;
    }
  }

  &__editing{
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 120px;
    width: 100%;
  }

  &__value{
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  }

  &__button {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
    width: 42px;
    border-radius: 50%;

    span{
      font-size: 18px;
    }
  }
}
</style>
