<i18n>
{
  "ru": {
    "pets": "С питомцами"
  },
  "en": {
    "pets": "With pets"
  }
}
</i18n>

<template>
  <div>
    <GuestsCounter
      @update:modelValue="adults = $event"
      :modelValue="guests.adults"
      class="adults"
    />
    <hr>
    <ChildrenSelect
      :children="guests.childrens"
      :childrenWithoutAgeError="childrenWithoutAgeError"
      @addChild="addChild"
      @changeChildAge="changeChildAge"
      @removeChild="removeChild"
    />
    <hr>
    <div class="select-guests__pets pets">
      <span class="pets-text">{{ $t("pets") }}</span>
      <UISwitcher v-model="petsValue"/>
    </div>
  </div>
</template>

<script>
import GuestsCounter from "./GuestsCounter";
import ChildrenSelect from "./ChildrenSelect";
import UISwitcher from "../UIKit/UISwetcher"

export default {
  name: "LightGuestOption",
  components: {
    GuestsCounter,
    ChildrenSelect,
    UISwitcher
  },
  data(){
    return{
      adults: 1,
      children: [],
      petsValue: false
    }
  },
  props:{
    guests:{
      type: Object,
      default: () => {
        return {
          adults: 1,
          childrens: [],
          pets: {
            value: false,
            description: "",
          }
        }
      }
    },
    childrenWithoutAgeError: Boolean,
  },
  emits: ["changeChildren", "changeAdults", "changePetsValue"],
  watch:{
    adults(val) {
      this.$emit("changeAdults", val);
    },
    children(val) {
      this.$emit("changeChildren", val);
    },
    petsValue(val) {
      this.$emit("changePetsValue", val);
    },
  },
  created(){
    this.adults = this.guests?.adults || 1;
    this.children = this.guests?.childrens || [];
    this.petsValue = this.guests?.pets?.value || false;
  },
  methods: {
    addChild(remove = false) {
      if (this.children.length >= 10 && !remove) {
        return false;
      }
      if(remove) {
        let target = [...this.children];
        if(target.length > 1) {
          target.pop();
        } else {
          target = [];
        }
        this.children = target;
        return;
      }
      this.children = [...this.children, null];
    },
    changeChildAge(value, index) {
      this.children = this.children.map((elem, i) => {
        if (index === i) {
          return value;
        }
        return elem;
      });
    },
    removeChild(index) {
      this.children = this.children.filter((elem, i) => i !== index);
    },
  },
};
</script>

<style lang="scss" scoped>
.select-guests {
  &__pets{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
  }
}

hr{
  margin: 15px 0;
  border-top: 1px solid #E7E9F3;
  transform: translateX(-8%);
  width: 119%;
}
</style>