<i18n>
{
	"ru": {
		"calendarTitle": "Выбор дат",
		"found": "нет вариантов|Показать {n} вариант|Показать {n} варианта|Показать {n} вариантов",
		"in": "Заезд",
		"btn": "Показать варианты",
		"out": "Отъезд",
		"reset": "Сбросить даты"
	},
	"en": {
		"calendarTitle": "Choose dates",
		"found": "no variants|Show {n} variant|Show {n} variants|Show {n} variants",
		"in": "Check-in",
		"btn": "Show variants",
		"out": "Check-out",
		"reset": "Clear dates"
	}
}
</i18n>
<template>
  <Modal
    ref="baseModalRefCalendar"
    :title="`${showReset ? '' : $t('calendarTitle')}`"
    @close="closeModal"
  >
    <!-- <transition name="main-datepicker-reset--fade"> -->
    <div
      v-show="showReset"
      slot="side-action"
      class="main-datepicker-reset"
      @click="reset"
    >
      {{ $t("reset") }}
    </div>

    <div class="main-datepicker">
      <div class="main-datepicker-calendar">
        <CalendarMobile
          ref="CalendarRef"
          @choice="showReset = $event"
          :dateViews.sync="dateViews"
          :datesInsert="$attrs.value"
          :fill-mode="fillMode"
        />

        <transition name="main-datepicker-calendar--footer--fade">
          <div v-show="isSelectedDate" class="main-datepicker-calendar--footer">
            <button
              class="button button_size_md button_dark-gray button_w-100"
              @click="apply"
            >
              {{
                button
                  ? totalFound === null
                    ? button
                    : $tc("found", totalFound)
                  : $t("btn")
              }}
            </button>
          </div>
        </transition>
      </div>
    </div>
  </Modal>
</template>
<script>
import CalendarMobile from "./CalendarMobile.vue";
import Modal from '@sutochno/modal'
import eventsDispatcher from "./eventsDispatcher.js";

import { mapState, mapGetters } from "vuex";
import _ from "lodash";

export default {
  name: "DatePickerModal",
  components: {
    // Calendar,
    Modal,
    // Dates,
    CalendarMobile
  },
  inject: ['isPlatform'],
  props: {
    isCounter: {
      default: false,
      type: Boolean,
    },
    button: {
      default: null,
      type: String,
    },
    fillMode: {
      type: String,
      default: 'in'
    }
  },
  watch: {
    isSelectedDate(value) {
      this.$emit("choice", value);
    },
  },
  methods: {
    show() {
      this.$refs.baseModalRefCalendar.show();
      eventsDispatcher('WIDGET_MODAL_SHOW', document)
    },
    format(date) {
      return date.toLocaleString("ru-RU", {
        day: "numeric",
        month: "long",
        year: "numeric",
      });
    },
    inputEmit() {
      if(this.isPlatform) {
        this.$emit('input', this.dateViews)
      } else {
        this.$emit("input", {
          in: this.checkIn,
          out: this.checkOut
        });
      }
    },
    apply() {
      this.$refs.baseModalRefCalendar.close();
      this.inputEmit()
      this.$emit('apply')
    },
    reset() {
      this.$refs.CalendarRef.reset();
      this.setOccupied();
      this.inputEmit()
      this.$emit('reset')
    },
    setOccupied() {
      // Нужно хранить нормальную дату, а не калечную
      this.$store.commit("search/setCheckIn", null);
      this.$store.commit("search/setCheckOut", null);
    },
    closeModal() {
      this.inputEmit()
      eventsDispatcher('WIDGET_MODAL_CLOSE', document)
    }
  },
  computed: {
    ...mapGetters("search", ["occupied", "maxGuests"]),
    ...mapState("search", ["filters", "location", "checkIn", "checkOut"]),
    isSelectedDate() {
      return this.dateViews.in && this.dateViews.out
    }
  },
  data() {
    return {
      dateViews: {
        in: null,
        out: null
      },
      totalFound: null,
      showReset: false
    };
  },
};
</script>
<style lang="scss" scoped>
.main-datepicker-calendar--footer--fade-enter-active,
.main-datepicker-calendar--footer--fade-leave-active {
  transform: translateY(0%);
  transition: transform 0.15s ease;
}
.main-datepicker-calendar--footer--fade-enter,
.main-datepicker-calendar--footer--fade-leave-to {
  transform: translateY(100%);
}
.main-datepicker {
  color: black;
  &-reset {
    position: absolute;
    top: 0px;
    right: 10px;
    color: white;
    z-index: 201;
  }
  &-input {
    color: #717171;
    position: sticky;
    top: 0px;
    z-index: 201;
    background: white;
    &--in,
    &--out {
      text-align: center;
      display: inline-block;
      width: 50%;
      box-sizing: border-box;
      padding: 12px 0;
      border: 1px solid #d8d8d8;
      &__fill {
        text-transform: capitalize;
        color: black;
      }
    }
    &--out {
      border-left-width: 0;
    }
  }
  &-calendar {
    &--footer {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 15px 20px;
      background-color: white;
      box-shadow: 0 0px 4px 0px #757575;
    }
  }
}
.button_dark-gray {
  background: #444;
  border: solid 1px rgba(0, 0, 0, 0.15);
  color: #fff;
  &:hover {
    background: #3a3a3a;
    border: solid 1px rgba(0, 0, 0, 0.2);
  }
}
</style>
